import { localErrorsField } from './apolloLocalErrors';

// Here goes all local fields
export default {
  Query: {
    fields: {
      ...localErrorsField,
    },
  },
  ProgramGroups: {
    keyFields: ['locales', ['id']],
  },
  Programs: {
    keyFields: ['locales', ['displayTags', ['value']]],
  },
};
