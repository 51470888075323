import { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import HostedMFE from './Hosted.mfe';
import DemoMFE from './Demo.mfe';
import { ConfigProps } from './types/app';
import capitalizeFirstLetter from './helpers/capitalizeFirstLetter';
import './index.scss';

const appStyles = {
  display: 'grid',
  justifyContent: 'center',
  alignContent: 'center',
  height: '100vh',
  backgroundColor: '#f5f5f5',
};

const containerStyles: CSSProperties = {
  width: '500px',
  height: '600px',
  resize: 'both',
  border: '1px solid #dadada',
  overflow: 'auto',
};

// get props from query params
const urlParams = new URLSearchParams(window.location.search);

const configProps: ConfigProps = {
  appId: urlParams.get('appId') || 'pset-userprofile',
  locale: urlParams.get('locale') || 'en',
  saveText: capitalizeFirstLetter(urlParams.get('saveText')),
  cancelText: capitalizeFirstLetter(urlParams.get('cancelText')),
  continueText: capitalizeFirstLetter(urlParams.get('continueText')),
  env: (window as any).$profileEnvVars.REACT_APP_STAGE || 'dev',
  isCompanyHidden: urlParams.get('isCompanyHidden') === 'true',
};

const browserView = urlParams.get('browserView');
const mfeAuthToken = (window as any).$profileEnvVars['X-MFE-Authorization'] || '';

const AppContents = () => {
  return (
    <>
      {mfeAuthToken ? (
        <HostedMFE accessToken={mfeAuthToken} configProps={configProps} />
      ) : (
        <DemoMFE configProps={configProps} />
      )}
    </>
  );
};

export default function App() {
  if (browserView === 'fullpage') return <AppContents />;

  return (
    <div className="App" style={appStyles}>
      <div style={containerStyles}>
        <AppContents />
      </div>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
