import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@digital-hig/typography';
import IndustryDropdown from '@profile-ui/industry-dropdown';
import CompanyInput from '@profile-ui/company-input';
import Role from '@profile-ui/role';
import { filteredArray } from '../helpers';
import Link from '@mui/material/Link';
import SuccessFields from './SuccessFields';
import FieldSkeleton from './skeletons/FieldSkeleton';
import LabelSkeleton from './skeletons/LabelSkeleton';
import Skeleton from '@mui/material/Skeleton';
import ConditionalRender from './common/ConditionalRender';
interface IRoleInput {
  roleId: string;
  roleName: string;
}

type FCProps = {
  description: string;
  locale: string;
  companyValue: string;
  industryValue: Array<string>;
  roleValue: IRoleInput;
  onValueChange: Function;
  isSuccess: boolean;
  successTitle: string;
  successMessage: string | React.ReactNode;
  linkText: string;
  linkUrl: string;
  industryLabel: string;
  roleLabel: string;
  companyLabel: string;
  industryProps?: object;
  roleProps?: object;
  companyProps?: object;
  isLoading?: boolean;
  isDataParsed?: boolean; // required to avoid the MUI uncontrolled error for role and industry
  isCompanyHidden?: boolean;
};

const Fields = ({
  description,
  locale,
  companyValue = '',
  industryValue = [],
  roleValue,
  onValueChange,
  isSuccess,
  successTitle,
  successMessage,
  linkText,
  linkUrl,
  industryLabel,
  roleLabel,
  companyLabel,
  industryProps,
  roleProps,
  companyProps,
  isLoading = false,
  isDataParsed = false,
  isCompanyHidden = false,
}: FCProps): JSX.Element =>
  !isSuccess ? (
    <>
      <Box pb={6}>
        <ConditionalRender
          isLoading={isLoading}
          skeletonComponent={
            <Skeleton
              variant="rectangular"
              width="100%"
              height={24}
              sx={{ marginBottom: '24px' }}
            />
          }
          component={<Typography variant="body-copy-medium">{description}</Typography>}
        />
      </Box>
      <Box pb={6}>
        <ConditionalRender
          isLoading={!isDataParsed}
          skeletonComponent={<FieldSkeleton />}
          component={
            <IndustryDropdown
              locale={locale}
              currentSelectedValues={industryValue}
              onSelectedIndustriesChanged={(e: any) =>
                onValueChange(
                  filteredArray(e, ['__typename', 'segmentName']),
                  'industryValue'
                )
              }
              formControlProps={{ label: industryLabel }}
              {...industryProps}
            />
          }
        />
      </Box>
      <Box pb={6}>
        <ConditionalRender
          isLoading={!isDataParsed}
          skeletonComponent={<FieldSkeleton />}
          component={
            <Role
              language={locale}
              currentSelectedValue={roleValue}
              labelText={roleLabel}
              onSelectedValueChanged={(e: any) => onValueChange(e, 'roleValue')}
              {...roleProps}
            />
          }
        />
      </Box>
      {!isCompanyHidden && (
        <Box pb={6}>
          <ConditionalRender
            isLoading={isLoading}
            skeletonComponent={<FieldSkeleton />}
            component={
              <CompanyInput
                id="companyInput"
                label={companyLabel}
                onBlurCallback={null}
                onChange={(e: any) => onValueChange(e, 'companyValue')}
                value={companyValue}
                {...companyProps}
              />
            }
          />
        </Box>
      )}
      <Box pb={6}>
        <ConditionalRender
          isLoading={isLoading}
          skeletonComponent={<LabelSkeleton />}
          component={
            <Link href={linkUrl} target="_blank">
              <Typography variant="body-copy-small" component="span">{linkText}</Typography>
            </Link>
          }
        />
      </Box>
    </>
  ) : (
    <SuccessFields successMessage={successMessage} successTitle={successTitle} />
  );

export default Fields;
