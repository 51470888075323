import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const FieldSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      width={100}
      height={18}
      sx={{ marginBottom: '4px' }}
      role="figure"
    />
  </>
);

export default FieldSkeleton;
