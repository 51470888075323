//TODO: This is a duplicate of utilities because of logger referring to shared components, it should be cleaned up
// once we move the logger to utilities.

import { useEffect, useState } from 'react';
import { sendLog } from '../helpers/logs/ProfileLogger';
import { trigger } from '@profile-ui-mono/utilities/helpers/customEventListener';

interface authConfigType {
  clientId: string;
  environment: string;
  redirectUri: string;
  scope: string;
}

const authConfig: authConfigType = {
  clientId: (window as any).$profileEnvVars.ADSK_APS_CLIENT_ID,
  environment: (window as any).$profileEnvVars.REACT_APP_STAGE,
  redirectUri: window.location.origin + '/pkceredirect.html',
  scope: 'user:read data:read user:write openid',
};

const useAuthRegister = () => {
  // This reference is created inside hook to allow for CFP mock()
  const auth = (window as any).cfp.providers.auth;
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    (async function login() {
      try {
        await auth.register(authConfig);
        const result = await auth.isLoggedIn();
        setLoggedIn(result);

        if (!result) auth.login();
      } catch (e) {
        sendLog(e, 'useAuthRegister', 'utilities/hooks');
        // This is a custom event that signals user is logging out
        trigger('USER_SIGNING_OUT');
        auth.logout();
      }
    })();
  }, []);

  return loggedIn;
};

export default useAuthRegister;
