import React from 'react';
import Typography from '@digital-hig/typography';
import Avatar from '@mui/material/Avatar';

type FCProps = {
  message: string;
  imgSrc?: string;
};

const Header = ({ message, imgSrc }: FCProps): JSX.Element => (
  <div style={{ display: 'flex', 'alignItems': 'center' }}>
    <Avatar alt={message} src={imgSrc} sx={{ width: 48, height: 48 }} />
    <Typography
      pl={4}
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      variant="headline-small"
    >
      {message}
    </Typography>
  </div>
);

export default Header;
