import { sendCustomLog } from '../helpers/logs/ProfileLogger';

interface InitOptions {
  appId?: string;
  context?: string;
}

interface UseAnalytics {
  logEvent: (msg?: string) => void;
}

const useAnalytics = ({
  appId = 'no app id',
  context = 'Profile MFE',
}: InitOptions = {}): UseAnalytics => {
  const logEvent = (msg: string = 'Generic log event message') => {
    sendCustomLog(context, `${msg}, app ID: ${appId}`);
  };

  return { logEvent };
};

export default useAnalytics;
