import { makeVar } from '@apollo/client';

// Instantiate and set initial state for the reactive variable
const localErrors = makeVar([]);

// Set this local field in the session storage
const localErrorsField = {
  localErrors: {
    read() {
      return localErrors();
    },
  },
};

let errorCounter = 0;
const currentErrorList = () => localErrorsField.localErrors.read();

const addError = (error) => {
  const newError = {
    id: errorCounter,
    ...error,
  };
  localErrors([...currentErrorList(), newError]);
  errorCounter += 1;
};

const removeError = (errorId) => {
  const newErrorList = currentErrorList().filter((err) => err.id !== errorId);
  localErrors(newErrorList);
};

export { localErrors, localErrorsField, addError, removeError };
