//TODO: This is a duplicate of utilities because of logger referring to shared components, it should be cleaned up
// once we move the logger to utilities.

// Helper methods for user provider.
import jwt_decode from 'jwt-decode';
import { sendLog } from './logs/ProfileLogger';

interface TokenParams {
  userid: string;
}

const getUserIdFromToken = (accessToken: string) => {
  try {
    const decodedToken: TokenParams = jwt_decode(accessToken);
    if (decodedToken?.userid) {
      return decodedToken.userid;
    }
  } catch (err) {
    //TODO log error & display error screen
    sendLog(err, `Error parsing access token, ${accessToken}`, 'getUserIdFromToken');
    return null;
  }
};

export default getUserIdFromToken;
