import React from 'react';
import Alert from '@mui/material/Alert';
import { AlertIcon } from '@digital-hig/alert';
import Box from '@mui/material/Box';

type ErrorAlertType = {
  message?: string;
};

const ErrorAlert = ({
  message = 'An unknown error occurred',
}: ErrorAlertType) => (
  <Box mb={4}>
    <Alert
      icon={<AlertIcon alt="error icon" severity="error" variant="filled" />}
      severity="error"
      variant="filled"
      sx={{ color: 'white !important' }} //TODO: remove this once the DHIG fixes the color issue
    >
      {message}
    </Alert>
  </Box>
);

export default ErrorAlert;
