import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Modal from '@digital-hig/modal';

type ProfileMFEViewSelectorProps = {
  isModalOpen: boolean;
  onClose: () => void;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  modalProps?: object;
  industryProps?: object;
  roleProps?: object;
  companyProps?: object;
  variant: string;
  isError?: boolean;
};

function ProfileMFEViewSelector({
  onClose,
  isModalOpen,
  header,
  footer,
  children,
  variant,
  modalProps,
  isError,
}: ProfileMFEViewSelectorProps) {
  return (
    <>
      {variant === 'modal' ? (
        <Modal
          closeButtonDisplay={isError ? false : true}
          disableCloseFromOutside
          maxWidth="sm"
          fullWidth
          styleVariant="subdivision"
          type={isError ? 'alert' : 'modal'}
          iconName={isError ? 'error' : null}
          open={isModalOpen}
          handleClose={onClose}
          title={<Box sx={{ paddingBottom: '24px' }}>{header}</Box>}
          content={children}
          actions={footer}
          {...modalProps}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            background: 'white',
          }}
        >
          {!isError && (
            <>
              <Box sx={{ padding: '1.5rem' }}>{header}</Box>
              <Divider light />
            </>
          )}
          <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '1.5rem' }}>{children}</Box>
          <Divider light />
          <Box sx={{ padding: '1.5rem', display: 'flex', justifyContent: 'flex-end' }}>
            {footer}
          </Box>
        </Box>
      )}
    </>
  );
}

export default ProfileMFEViewSelector;
