import { MFEStates } from '../types/app';
import { v4 as uuidv4 } from 'uuid';
interface CloudEventParams {
  specversion?: string;
  id?: string;
  time?: string;
  type: MFEStates;
  source?: string;
  data?: string | object;
}

const windowEmitter = {
  send(cloudevent: CloudEventParams) {
    const event = new CustomEvent(cloudevent.type, {
      detail: cloudevent,
    });
    window.dispatchEvent(event);
  }
};

// Custom CloudEvent as per spec: https://github.com/cloudevents/spec
const createCustomCloudEvent = (params: CloudEventParams) => {
  return {
    specversion: "1.0",
    type: params.type,
    source: params.source,
    id: uuidv4(),
    time: new Date().toISOString(),
    data : params.data
  };
};

const getCloudEvent = ({ type, source='profile-mfe', data }:CloudEventParams) => {
  const cloudevent = createCustomCloudEvent({
    type: type,
    source: source,
    data: data,
  });
  return cloudevent;
};

const sendCloudEvent = (cloudevent: CloudEventParams) => {
  windowEmitter.send(cloudevent);
};

export { getCloudEvent, sendCloudEvent, CloudEventParams };