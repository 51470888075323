import { useState, useEffect, FC } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { parseAemStitched } from './aemParsers';
import { getAemLocale } from './localeMaps';
import { DEFAULT_ISO_LOCALE } from "../constants/locales";
import fetchContent from './fetchContent';
import { LocaleWrapperProps } from '../types/translation';
import EnvVars from '../constants/env';
import { Environment } from '../types/app';

/**
 * Set up global i18n instance using root lng
 * Using the key-values parsed from AEM
 */
const setupI18n = (translation: object): void => {
  const resources = { root: { translation } };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: 'root',
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false,
      },
    });
};

const I18nConfigs = {
  lng: DEFAULT_ISO_LOCALE,
  fallbackLng: DEFAULT_ISO_LOCALE,
  defaultNS: '',
  debug: false,
  resources: {},
  interpolation: { escapeValue: false },
  transSupportBasicHtmlNodes: true,
};

const useLocaleContent = (locale: string, env: Environment): boolean => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!locale) return;

    const i18nInstance = i18n.createInstance();
    i18nInstance.use(initReactI18next).init(I18nConfigs);
    
    const aemLocale = getAemLocale(locale);
    const url = EnvVars[env].ADSK_AEM_URL;
    
    (async function setupAemContent() {
      const rawContent = await fetchContent(url, aemLocale);
      const parsedAemContent = parseAemStitched(rawContent);
      setupI18n(parsedAemContent);
      setLoading(false);
    })();
  }, [locale]);

  return loading;
};

// A render-blocking wrapper that fetches, parses, and sets up localized content using i18n
const LocaleWrapper: FC<LocaleWrapperProps> = ({ children, locale, env, skeletons = null }) => {
  const loading = useLocaleContent(locale, env);
  return loading ? skeletons : children;
};

export default LocaleWrapper;
