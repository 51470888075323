import { FC, ReactNode } from 'react';

interface ConditionalRenderProps {
  isLoading: boolean;
  skeletonComponent: ReactNode;
  component: ReactNode;
}

const ConditionalRender: FC<ConditionalRenderProps> = ({
  isLoading,
  skeletonComponent,
  component,
}) => (isLoading ? skeletonComponent : component);

export default ConditionalRender;
