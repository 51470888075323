import { useEffect, useState } from 'react';
import useStoreRedirect from '@profile-ui-mono/utilities/hooks/useStoreRedirect';
import useAuthRegister from './hooks/useAuthRegister';
import ProfileMFELoader from './components/ProfileMFELoader';
import { ConfigProps } from './types/app';

interface DemoMFEProps {
  configProps: ConfigProps;
}

export default function DemoMFE({ configProps }: DemoMFEProps) {
  const [accessToken, setAccessToken] = useState('');
  const isLoggedIn = useAuthRegister();
  useStoreRedirect();

  useEffect(() => {
    const auth = (window as any).cfp.providers.auth;
    if (auth) {
      auth.getAccessToken().then((token: string) => {
        setAccessToken(token);
      });
    }
  }, []);

  if (!isLoggedIn || !accessToken) return <p>{'Loading Profile MFE...'}</p>;

  return <ProfileMFELoader accessToken={accessToken} {...configProps} />;
}
