import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import LabelSkeleton from './LabelSkeleton';
const FieldSkeleton = () => (
  <>
    <LabelSkeleton />
    <Skeleton
      variant="rectangular"
      width="100%"
      height={40}
      sx={{ marginBottom: '24px' }}
      data-testid="skeleton-rectangular"
    />
  </>
);

export default FieldSkeleton;
