import { AemSingleData, AemStitchedData, ParsedOutput } from '../types/translation';

// Parse the single CF response from AEM
export const parseAemSingle = (data: AemSingleData): ParsedOutput => {
  const elements = data.properties.elements;

  const output = Object.keys(elements).reduce((acc: ParsedOutput, key: string) => {
    acc[key] = elements[key].value;
    return acc;
  }, {} as ParsedOutput);
  return output;
};

// Parse a stitched (more than one CF) response from AEM
export const parseAemStitched = (data: AemStitchedData): ParsedOutput => {
  const entities = data?.entities || [];
  const output: ParsedOutput = {};

  entities.forEach((ent) => {
    for (const [key, element] of Object.entries(ent.properties.elements)) {
      output[key] = element.value;
    }
  });
  return output;
};
