import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const FooterSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      width={100}
      height={40}
      sx={{ marginRight: '10px' }}
      role="figure"
    />
    <Skeleton variant="rectangular" width={100} height={40} role="figure" />
  </>
);

export default FooterSkeleton;
