/* istanbul ignore file */
import { gql } from '@apollo/client';

const GET_MFE_PROFILE_INFO = gql`
  query getUserProfileQuery($id: String!, $lang: String) {
    getUserProfile(id: $id) {
      profileImages {
        hasProfileImage
        thumbnails {
          sizeX120
        }
      }
      personalInfo {
        basicInfo {
          firstName
          lastName
          screenName
        }
        professionalInfo {
          jobTitle
          company {
            companyName
          }
          additionalProfessionalInfo {
            roleId
            industryTaxonomyIds {
              industryGroupId
              segmentId
              subSegmentId
            }
          }
        }
      }
    }
    getRoles(lang: $lang) {
      roles {
        roleId
        roleName
      }
    }
    getSubSegments(lang: $lang) {
      subSegments {
        segmentId
        subSegmentId
        subSegmentName
        industryGroupId
      }
    }
  }
`;

export default GET_MFE_PROFILE_INFO;
