type RoleStateType = {
  roleId: string;
  roleName: string;
};

interface SubSegmentObject {
  subSegmentId: string;
}

export const matchObjectsByID = (
  arrayOfStrings: string[],
  arrayOfObjects: SubSegmentObject[]
): SubSegmentObject[] =>
  arrayOfObjects.filter((obj) => arrayOfStrings.includes(obj.subSegmentId));

export const filteredArray = (arr: Array<any>, properties: Array<any> = []) => {
  return arr.map((item) => {
    let newItem = { ...item };
    properties.forEach((property) => {
      delete newItem[property];
    });
    return newItem;
  });
};

export const getSelectedRole = (
  arr: RoleStateType[],
  selectedId?: string,
  selectedName?: string
) => {
  let result;
  if (selectedId) {
    result = arr.find((item) => selectedId === item.roleId);
  } else if (selectedName) {
    result = arr.find((item) => selectedName === item.roleName);
  }

  return result ? result : { roleId: '', roleName: '' };
};

export const selectedIndustryIds = (arrayOfObjects: Array<any>) => {
  return arrayOfObjects.map((obj) => obj.subSegmentId);
};
