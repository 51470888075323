import React, { ReactNode } from 'react';
import { CustomError } from '../Error/CustomError';
import { sendLog } from '../../helpers/logs/ProfileLogger';

// Since errors thrown during the initial rendering phase are not caught by error boundaries,
// we need to wrap the children of the ErrorBoundary component in a TokenChecker component to check if token is provided.

const TokenChecker: React.FC<{
  token: string;
  children: ReactNode;
  errorMessage?: string;
  appId?: string;
}> = ({ token, appId, children }) => {
  if (!token) {
    sendLog(null, 'TokenError', `Profile MFE - ${appId}`);
    throw new CustomError({
      title: 'Token Error',
      message: 'Please provide a token',
    });
  }
  return <>{children}</>;
};

export default TokenChecker;
