function subscribe(eventType, listener) {
  document.addEventListener(eventType, listener);
}

function unsubscribe(eventType, listener) {
  document.removeEventListener(eventType, listener);
}

function trigger(eventType, eventInit) {
  const event = new CustomEvent(eventType, eventInit);
  document.dispatchEvent(event);
}

export { subscribe, unsubscribe, trigger };
