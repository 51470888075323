import { DEFAULT_ISO_LOCALE, DEFAULT_SES_LOCALE } from "../constants/locales";

interface LocaleMap {
  [key: string]: string;
}

const oxygenToAemLocaleMap: LocaleMap = {
  cs: 'cs-CZ', // Czech
  da: 'da-DK', // Danish
  de: 'de-DE', // German
  en: 'en-US', // English
  es: 'es-ES', // Spanish
  fi: 'fi-FI', // Finnish
  fr: 'fr-FR', // French
  fr_ca: 'fr-CA', // French (Canada)
  hu: 'hu-HU', // Hungarian
  it: 'it-IT', // Italian
  ja: 'ja-JP', // Japanese
  ko: 'ko-KR', // Korean
  nb: 'no-NO', // Norwegian
  nl: 'nl-NL', // Dutch
  pl: 'pl-PL', // Polish
  pt_br: 'pt-BR', // Portuguese (Brazil)
  pt_pt: 'pt-PT', // Portuguese (Europe)
  ru: 'ru-RU', // Russian
  sv: 'sv-SE', // Swedish
  tr: 'tr-TR', // Turkish
  zh_cn: 'zh-CN', // Simplified Chinese
  zh_hk: 'zh-TW', // Traditional Chinese (Hong Kong)
  zh_tw: 'zh-TW', // Traditional Chinese (Taiwan)
};

const oxygenToSesLocaleMap: LocaleMap = {
  cs: 'cs', // Czech
  da: 'da', // Danish
  de: 'de', // German
  en: 'en', // English
  es: 'es', // Spanish
  fi: 'fi', // Finnish
  fr: 'fr', // French
  fr_ca: 'fr-ca', // French (Canada)
  hu: 'hu', // Hungarian
  it: 'it', // Italian
  ja: 'ja', // Japanese
  ko: 'ko', // Korean
  nb: 'no', // Norwegian
  nl: 'nl', // Dutch
  pl: 'pl', // Polish
  pt_br: 'pt', // Portuguese (Brazil)
  pt_pt: 'pi', // Portuguese (Europe)
  ru: 'ru', // Russian
  sv: 'sv', // Swedish
  tr: 'tr', // Turkish
  zh_cn: 'zh', // Simplified Chinese
  zh_hk: 'yue-hk', // Traditional Chinese (Hong Kong)
  zh_tw: 'cv', // Traditional Chinese (Taiwan)
};

export const getAemLocale = (locale: string): string => oxygenToAemLocaleMap[locale.toLowerCase()] || DEFAULT_ISO_LOCALE;
export const getSesLocale = (locale: string): string => oxygenToSesLocaleMap[locale.toLowerCase()] || DEFAULT_SES_LOCALE;
