interface CustomErrorProps {
  title: string;
  message: string;
}

export class CustomError extends Error {
  public title: string;

  constructor({ title, message }: CustomErrorProps) {
    super(message);
    this.title = title;
  }
}
