import { useEffect } from 'react';

const STORAGE_KEY = 'profile_redirect_url';

const useStoreRedirect = () => {
  useEffect(() => {
    // For Security App ID Token Reauth flow
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('state');
    window.sessionStorage.setItem(STORAGE_KEY, currentUrl.toString());
  }, []);
};

export default useStoreRedirect;
