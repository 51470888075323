import axios, { AxiosRequestConfig } from 'axios';

interface Urls {
  infoUrl: string;
  errorUrl: string;
}

let logInstance: LogService | null = null;

const getHeaders = (traceId: string): AxiosRequestConfig => {
  return {
    headers: {
      'x-amzn-trace-id': traceId,
      Authorization: 'D1e637-hf4f4-gh64-k901',
    },
  };
};

class LogService {
  private infoUrl: string;
  private errorUrl: string;
  private traceId: string;

  constructor(urls: Urls, traceId: string) {
    this.infoUrl = urls.infoUrl;
    this.errorUrl = urls.errorUrl;
    this.traceId = traceId;
  }

  setTraceId = (traceId: string): void => {
    this.traceId = traceId;
  };

  sendInfoLog = async (data: any): Promise<void> => {
    try {
      await axios.post(this.infoUrl, data, getHeaders(this.traceId));
    } catch (err) {
      throw new Error('Logservice failed to sendInfoLog');
    }
  };

  sendErrorLog = async (data: any): Promise<void> => {
    try {
      await axios.post(this.errorUrl, data, getHeaders(this.traceId));
    } catch (err) {
      throw new Error('Logservice failed to sendErrorLog');
    }
  };
}

export default {
  init: (urls: Urls, traceId: string): LogService => {
    if (!logInstance) {
      logInstance = new LogService(urls, traceId);
    }
    return logInstance as LogService;
  },

  getInstance: (): LogService | null => {
    return logInstance;
  },
};
