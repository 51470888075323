// https://docs.aws.amazon.com/xray/latest/devguide/xray-api-sendingdata.html#xray-api-traceids
import { v4 as uuidv4 } from 'uuid';

function getTraceId() {
  const unixEpochTime = (Date.now() / 1000) | 0;
  const hexValue = unixEpochTime.toString(16);
  const uuid = uuidv4().replace(/-/g, ''); //TODO : replace uuidv4 library with window.crypto.randomUUID() when it is supported by all browsers
  return `Root=1-${hexValue}-${uuid}`;
}

export default getTraceId;
