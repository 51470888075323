import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@digital-hig/typography';
import SvgComplete from '@digital-hig/icon/lib/build/display-icons/complete';
import SvgIcon from '@mui/material/SvgIcon';
import Link from '@mui/material/Link';

type FCProps = {
  successTitle?: string;
  successMessage?: string | React.ReactNode;
};

const SuccessFields = ({
  successTitle = 'Information saved',
  successMessage = '',
}: FCProps): JSX.Element => (
  <Box pb={9} pt={7}>
    <SvgIcon
      fontSize="large"
      sx={{
        paddingBottom: '0 !important',
        width: '48px !important',
        height: '48px !important',
      }}
    >
      <SvgComplete />
    </SvgIcon>
    <Box pt={4} pb={2}>
      <Typography variant="headline-smaller">{successTitle}</Typography>
    </Box>
    <Box pb={9}>
      <Typography variant="body-copy-medium">{successMessage}</Typography>
    </Box>
  </Box>
);

export default SuccessFields;
