/* istanbul ignore file */
import { gql } from '@apollo/client';

const UPDATE_MFE_PROFILE_WITH_COMPANY = gql`
  mutation UpdateUserProfile($input: UserInput!) {
    updateUserProfile(input: $input) {
      personalInfo {
        professionalInfo {
          company {
            companyName
          }
          jobTitle
          additionalProfessionalInfo {
            roleId
            industryTaxonomyIds {
              industryGroupId
              segmentId
              subSegmentId
            }
          }
        }
      }
    }
  }
`;

const UPDATE_MFE_PROFILE_WITHOUT_COMPANY = gql`
  mutation UpdateUserProfile($input: UserInput!) {
    updateUserProfile(input: $input) {
      personalInfo {
        professionalInfo {
          jobTitle
          additionalProfessionalInfo {
            roleId
            industryTaxonomyIds {
              industryGroupId
              segmentId
              subSegmentId
            }
          }
        }
      }
    }
  }
`;

export { UPDATE_MFE_PROFILE_WITH_COMPANY, UPDATE_MFE_PROFILE_WITHOUT_COMPANY };
