import React from 'react';
import ProfileMFELoader from './components/ProfileMFELoader';

export default function HostedMFE({ accessToken, configProps }: any) {
  // Revit usecase to add the callback hooks
  const sharedWebView = (window as any)?.chrome?.webview || null;
  if (sharedWebView) {
    const profileHost = sharedWebView?.hostObjects?.ProfileHost; // ProfileHost is the object that is exposed from the revit app
    configProps = {
      ...configProps,
      onClose: profileHost && profileHost.OnClose,
      onSave: (data: object) => {
        if (profileHost && profileHost.OnSave) {
          profileHost.OnSave(JSON.stringify(data)); // Revit expects the data to be stringified
        }
      },
    };
  }

  return (
    <>{accessToken && <ProfileMFELoader accessToken={accessToken} {...configProps} />}</>
  );
}
