const FALLBACK_CONTENT = {
  entities: [    
    {
      properties: {
        elements: {
          CONTINUE_BUTTON: {
            value: 'Continue',
          },
          SUCCESS_MESSAGE: {
            value:
              'You can view and edit your information at any time in your <0>profile</0>.',
          },
          WELCOME_MESSAGE: {
            value: 'Hi, {{firstName}} {{lastName}}',
          },
          DESCRIPTION_TEXT: {
            value:
              'Tailor your experience with Autodesk based on the work that you do.',
          },
          CANCEL_BUTTON: {
            value: 'Close',
          },
          PRIVACY_LINK_TEXT: {
            value: 'Privacy Statement',
          },
          SAVE_BUTTON: {
            value: 'Save',
          },
          ERROR_MESSAGE_GENERIC: {
            value: 'An error occurred, please try again later.',
          },
          SUCCESS_TITLE: {
            value: 'Thank you',
          },
          INDUSTRY_LABEL: {
            value: 'Industry',
          },
          ROLE_LABEL: {
            value: 'Role',
          },
          COMPANY_LABEL: {
            value: 'Company',
          },
        },
      },
    },
  ],
};

export default FALLBACK_CONTENT;
