import FALLBACK_CONTENT from './fallbackContent';
import { AemStitchedData } from '../types/translation';

const fetchContent = async (
  url: string,
  locale: string
): Promise<AemStitchedData> => {
  const aemUrl = `${url}${locale}/userprofile-mfe.json`;
  let result;

  try {
    result = await fetch(aemUrl).then((res) => res.json());
  } catch (e) {
    result = FALLBACK_CONTENT;
  }

  return result;
};

export default fetchContent;
