import React from 'react';
import ProfileMFE from './ProfileMFE';

export default function ProfileMFELoader({
  appId,
  locale,
  saveText,
  cancelText,
  continueText,
  env,
  isCompanyHidden,
  accessToken,
  onClose = () => {},
  onSave = () => {},
}: any) {
  return (
    accessToken && (
      <ProfileMFE
        env={env}
        appId={appId}
        locale={locale}
        saveText={saveText}
        cancelText={cancelText}
        continueText={continueText}
        token={accessToken}
        onClose={onClose}
        onSave={onSave}
        variant="basic"
        isCompanyHidden={isCompanyHidden}
        cancelBtnStyle={{ marginRight: '10px' }}
      />
    )
  );
}
