import React, { ReactNode } from 'react';
import ErrorComponent from './ErrorComponent';
import { CustomError } from './CustomError';
import { sendLog } from '../../helpers/logs/ProfileLogger';

type ErrorBoundaryProps = {
  children: ReactNode;
  appId?: string;
  errorMessage?: string;
  errorTitle?: string;
  buttonText?: string;
  isModalOpen?: boolean;
  variant?: string;
  onClose?: () => void;
};

type ErrorBoundaryState = {
  hasError: boolean;
  errorMessage: string;
  errorTitle: string;
  isOpen: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      errorTitle: '',
      errorMessage: '',
      isOpen: props.isModalOpen,
    };
  }

  componentDidCatch(error: any, info: any) {
    const isCustomError = error instanceof CustomError;
    sendLog(error, info, 'ProfileMFE::ErrorBoundary');
    this.setState({
      hasError: true,
      errorTitle: isCustomError ? error.title : this.props.errorTitle,
      errorMessage: isCustomError ? error.message : this.props.errorMessage,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComponent
          errorTitle={this.state.errorTitle}
          errorMessage={this.state.errorMessage}
          buttonText={this.props.buttonText}
          variant={this.props.variant}
          onClose={this.props.onClose}
          appId={this.props.appId}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
