import React, { useEffect } from 'react';
import ThemeMui from '@digital-hig/theme-mui';
import Button from '@digital-hig/button';
import ErrorIcon from '@digital-hig/icon/lib/build/display-icons/error';
import Typography from '@digital-hig/typography';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import useAnalytics from '../../hooks/useAnalytics';
import ProfileMFEViewSelector from '../ProfileMFEViewSelector';

type ErrorComponentProps = {
  appId?: string;
  errorTitle?: string;
  errorMessage?: string;
  buttonText?: string;
  isModalOpen?: boolean;
  variant?: string;
  onClose?: () => void;
};

function ErrorComponent({
  errorTitle = 'Something went wrong',
  errorMessage = 'An unexpected error occurred. Please try again later.',
  buttonText = 'Close',
  isModalOpen = true,
  variant = 'modal',
  appId,
  onClose,
}: ErrorComponentProps) {
  const { logEvent } = useAnalytics({ appId });

  useEffect(() => {
    logEvent(`${errorTitle}, ${errorMessage}`);
  }, []);

  return (
    <ThemeMui themePrefix="profile-error">
      <ProfileMFEViewSelector
        isModalOpen={isModalOpen}
        onClose={onClose}
        isError
        variant={variant}
        footer={
          <Button onClick={onClose} fullWidth variant="contained">
            {buttonText}
          </Button>
        }
      >
        <Box pt={7}>
          <SvgIcon fontSize="large" sx={{ paddingBottom: '0 !important' }}>
            <ErrorIcon />
          </SvgIcon>
          <Box mt={4}>
            <Typography variant="headline-small">{errorTitle}</Typography>
          </Box>
          <Box mt={2} mb={8}>
            <Typography variant="body-copy-medium">{errorMessage}</Typography>
          </Box>
        </Box>
      </ProfileMFEViewSelector>
    </ThemeMui>
  );
}

export default ErrorComponent;
