import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const HeaderSkeleton = () => (
  <div style={{ display: 'flex', alignItems: 'center' }} data-testid="skeleton">
    <Skeleton variant="circular" width={48} height={48} sx={{ marginRight: '10px' }} />
    <Skeleton variant="rectangular" width="50%" height={48} />
  </div>
);

export default HeaderSkeleton;
